import React, { Component } from 'react';
import "../scss/education.scss";

export class Education extends Component {
    static displayName = Education.name;

    constructor(props) {
        super(props);
        this.state = { experience: [], loading: true };

        fetch('api/data/Education')
            .then(response => response.json())
            .then(data => {
                this.setState({ education: data, loading: false });
            });
    }    

    renderEducationSection = (education) => {
        return (
            <div className="custom-education-content-row row">
                {education.map(edu =>
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">{edu.school}</h5>
                            <h6 class="card-subtitle mb-2 text-muted">{edu.start} - {edu.end}</h6>
                            <h6 class="card-subtitle mb-2 text-muted">{edu.degree} - {edu.major}</h6>
                            <h6 class="card-subtitle mb-2 text-muted">{edu.accolades}</h6>                            
                        </div>
                    </div>
                )}   
            </div>            
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderEducationSection(this.state.education);

        return (
            <div>
                <div className="custom-education-header">
                    <h2>Education</h2>                    
                </div>
                {contents}
            </div>
        );
    }
}
