import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
        <h2 class="card-title">Joe Naylor</h2>
        <h6 class="card-subtitle mb-2 text-muted">Full Stack Software Engineer</h6>
            <p className="custom-content-paragraph">
                Hello and thanks for stopping by my site. My name is Joe Naylor and I am a Full Stack Software Engineer with over 10 years of experience working with numerous
                languages, frameworks, software, and technologies. My primary interests are in web development utilizing .NET Core for handling backend work, with ReactJS or ASP MVC
                handling things on the frontend. I also have some experience working with AWS (Lambda, Elasticache, API Gateway, etc.) and am always actively working to expand my
                knowledge in cloud services.
            </p>
            <p className="custom-content-paragraph">
                Feel free to check out additional information on the site about me and my experiences over the years. You can find information about my past jobs, education,
                experience with different languages and technologies, as well as some of the more niche software products I have worked with during my career.
            </p>
            <p className="custom-content-paragraph">
                If you're looking for a new engineer for your team and want to get in touch with me, you can use the LinkedIn link to view my profile and send me a message.
                I am always open to considering new positions and would love to hear from you.
            </p>
        <p></p>
      </div>
    );
  }
}
