import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import "../scss/experience.scss";

export class Experience extends Component {
    static displayName = Experience.name;

    constructor(props) {
        super(props);
        this.state = { experience: [], loading: true };                

        fetch('api/data/Experience')
            .then(response => response.json())
            .then(data => {
                this.setState({ experience: data, activeTab: data[0].key, loading: false });
            });        
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    renderExperienceSection = (experience) => {        
        return (
            <Row className="custom-experience-content-row">
                <Col xs="6" sm="4" md="4">
                    <Nav tabs vertical pills>
                        {experience.map(exp =>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === exp.key })}
                                    href="#"
                                    onClick={() => {
                                        this.toggle(exp.key);
                                    }}
                                >
                                    {exp.title}
                                </NavLink>
                            </NavItem>
                        )}                          
                    </Nav>
                </Col>
                <Col xs="6" sm="6" md="6">
                    <TabContent activeTab={this.state.activeTab}>
                        {experience.map(exp =>
                            <TabPane tabId={exp.key}>
                                <h4>{exp.jobTitle}</h4>
                                <h6 className="text-muted">{exp.start} - {exp.end}, {exp.location}</h6>
                                <ul>
                                    {exp.description.map(desc =>
                                    <li>
                                    <span>
                                        {desc}
                                        <br />
                                        </span>
                                    </li>
                                    )}
                                </ul>
                            </TabPane>
                        )}                          
                    </TabContent>
                </Col>
            </Row>            
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderExperienceSection(this.state.experience);

        return (
            <div>
                <div className="custom-experience-header">                    
                    <h2>Experience</h2>                    
                </div>                
                {contents}
            </div>
        );
    }
}
