import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Experience } from './components/Experience';
import { Education } from './components/Education';
import { Proficiencies } from './components/Proficiencies';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons'

library.add(faStroopwafel)

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />        
        <Route path='/experience' component={Experience} />
        <Route path='/education' component={Education} />
        <Route path='/proficiencies' component={Proficiencies} />
      </Layout>
    );
  }
}
