import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames';
import "../scss/proficiencies.scss";

export class Proficiencies extends Component {
    static displayName = Proficiencies.name;

    constructor(props) {
        super(props);
        this.state = { proficiencies: [], loading: true };

        fetch('api/data/Proficiencies')
            .then(response => response.json())
            .then(data => {
                this.setState({ proficiencies: data, activeTab: data[0].category, loading: false });
            });
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }        

    renderProficienciesSection = (proficiencies) => {        
        return (
            <Row className="custom-proficiencies-content-row">
                <Col xs="6" sm="4" md="4">
                    <Nav tabs vertical pills>
                        {proficiencies.map(pro =>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === pro.category })}                                    
                                    href="#"
                                    onClick={() => {
                                        this.toggle(pro.category);
                                    }}
                                >
                                    {pro.category}
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                </Col>
                <Col xs="6" sm="6" md="6">
                    <TabContent activeTab={this.state.activeTab}>
                        {proficiencies.map(pro =>
                            <TabPane tabId={pro.category}>
                                <div className="card">
                                    <div className="card-body">
                                        {pro.proficiencies.map(prof =>
                                            <div className="custom-profiency-item">
                                                <div className="custom-text-container">{prof.title}</div>
                                                <div className="custom-star-container">
                                                    {(() => {                                            
                                                        const stars = [];
                                                        const starTotal = 5;
                                                        const starEmpty = starTotal - prof.rating;

                                                        if (starEmpty > 0) {
                                                            for (let i = starEmpty; i > 0; i--) {
                                                                stars.push(<i class="far fa-circle custom-circle-empty"></i>);
                                                            }
                                                        }

                                                        for (let i = prof.rating; i > 0; i--) {
                                                            stars.push(<i class="fas fa-circle custom-circle"></i>);
                                                        }
                                                    
                                                        return stars;
                                                        })()}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </TabPane>
                        )}
                    </TabContent>
                </Col>
            </Row>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderProficienciesSection(this.state.proficiencies);

        return (
            <div>
                <div className="custom-proficiencies-header">
                    <h2>Proficiencies</h2>                    
                </div>
                {contents}
            </div>
        );
    }
}
