import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../scss/nav.scss';

export class Nav extends Component {
    static displayName = Nav.name;

    render() {
        return (
            <div className="">
                <div className="middle-items">                   
                    <Link to="/">Home</Link>
                    <Link to="/Experience">Experience</Link>
                    <Link to="/Education">Education</Link>
                    <Link to="/Proficiencies">Proficiencies</Link>                    
                    <a href="https://www.linkedin.com/in/joseph-naylor-99423a40" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                </div>
                <div className="bottom-items">
                    <p>Powered by ReactJS & .NET Core</p>
                </div>
            </div>
        );
    }
}
