import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Nav } from './Nav';
import '../scss/page.scss';
import '../scss/nav.scss';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div className="container-fluid custom-page-container">
        <NavMenu />
        <div className="row h-100 custom-nav-row">
            <div className="col-lg-3 custom-center-align-column custom-nav-column">
                <Nav />               
            </div>
            <div className="col-lg-9 custom-center-align-column float-right">
                <Container className="custom-content-container">
                    {this.props.children}
                </Container>
            </div>
        </div>                
      </div>
    );
  }
}
